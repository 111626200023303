import React from 'react'
import LayoutWithHeaderAndFooter from '../components/layouts/LayoutWithHeaderAndFooter'
import {
  DisplayLarge,
  HeadingLarge,
  LabelSmall,
} from '../components/typography'
import SEO from '../components/seo'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import { Namespace } from '../constants/i18n'
import styled from '@emotion/styled'

const ResponsiveEmbedContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;

  & > iframe,
  & > object,
  & > embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const Contribution = (): JSX.Element => {
  const { t } = useTranslation(Namespace.Contribution)

  const contents = t('contents', { returnObjects: true }) as Array<string>

  return (
    <LayoutWithHeaderAndFooter>
      <SEO title="Contribution" />
      <div className="flex flex-col mx-auto space-y-8 max-w-screen-md">
        <DisplayLarge as="h1">{t('title')}</DisplayLarge>

        <div className="grid grid-cols-1 gap-4">
          {Array.isArray(contents) &&
            contents.map((content, index) => (
              <p key={index}>
                <Trans t={t} i18nKey={`contents.${index}`}>
                  {content}
                </Trans>
              </p>
            ))}
        </div>

        <div>
          <HeadingLarge as="h2" className="mb-2">
            {t('modelSimulation.heading')}
          </HeadingLarge>
          <p>{t('modelSimulation.description')}</p>
          <ResponsiveEmbedContainer className="mt-6 mb-2">
            <iframe
              title={t('modelSimulation.videoCaption')}
              src="https://www.youtube.com/embed/7OLpKqTriio"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </ResponsiveEmbedContainer>
          <LabelSmall className="text-center">
            {t('modelSimulation.videoCaption')}
          </LabelSmall>
        </div>
      </div>
    </LayoutWithHeaderAndFooter>
  )
}

export default Contribution
